
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol
} from "@ionic/vue";
import BaseContainer from "@/components/base/BaseContainer.vue";
import { ref } from "vue";

export default {
  name: "Calculator",
  setup() {
    const height = ref();
    const weight = ref();
    const bmi = ref();
    const bmiText = ref("");

    function onFormChange() {
      if (weight.value <=0 || height.value <= 0){
        bmi.value = 0
        return
      }
      bmi.value = (weight.value / height.value ** 2) * 10000;
      if (bmi.value <= 16) {
        bmiText.value = "Выраженный дефицит массы тела";
      } else if (bmi.value < 18.5) {
        bmiText.value = "Недостаточная (дефицит) масса тела";
      } else if (bmi.value < 25) {
        bmiText.value = "Норма";
      } else if (bmi.value < 30) {
        bmiText.value = "Избыточная масса тела (предожирение)";
      } else if (bmi.value < 35) {
        bmiText.value = "Ожирение";
      } else if (bmi.value < 40) {
        bmiText.value = "Ожирение резкое";
      } else if (bmi.value >= 40) {
        bmiText.value = "Очень резкое ожирение";
      } else {
        bmiText.value = "";
      }
    }
    return { height, weight, bmi, bmiText, onFormChange };
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonLabel,
    IonItem,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    BaseContainer
  },
};
